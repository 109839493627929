import React, {Component} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "assets/vendors/style"
import indigoTheme from './themes/indigoTheme';
import cyanTheme from './themes/cyanTheme';
import orangeTheme from './themes/orangeTheme';
import amberTheme from './themes/amberTheme';
import pinkTheme from './themes/pinkTheme';
import blueTheme from './themes/blueTheme';
import purpleTheme from './themes/purpleTheme';
import greenTheme from './themes/greenTheme';
import darkTheme from './themes/darkTheme';
import AppLocale from '../lngProvider';
import User from 'ait/system/User';
import ForcedPasswordReset from "ait/components/System/ForcedPasswordReset";
import {isEmpty} from "ait/system/UtilityFunctions";
import {
	AMBER,
	BLUE,
	CYAN,
	DARK_AMBER,
	DARK_BLUE,
	DARK_CYAN,
	DARK_DEEP_ORANGE,
	DARK_DEEP_PURPLE,
	DARK_GREEN,
	DARK_INDIGO,
	DARK_PINK,
	DEEP_ORANGE,
	DEEP_PURPLE,
	GREEN,
	INDIGO,
	PINK
} from 'constants/ThemeColors';

import AITApp from 'ait/index';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';


import {bindActionCreators} from 'redux';
import * as actionCreators from 'actions/index';

class App extends Component {
	state = {
		requestedPath: '', //Where to go if set
		requestSet: false, //Set to true to prevent overwrites of requested path
	};
	constructor(props) {
		super(props);
		window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
	}
	getColorTheme(themeColor) {
		if(themeColor != null && themeColor !== '') {
			document.body.classList.add(themeColor);
		}
		let applyTheme;
		switch (themeColor) {
			case INDIGO: {
				applyTheme = createMuiTheme(indigoTheme);
				break;
			}
			case CYAN: {
				applyTheme = createMuiTheme(cyanTheme);
				break;
			}
			case AMBER: {
				applyTheme = createMuiTheme(amberTheme);
				break;
			}
			case DEEP_ORANGE: {
				applyTheme = createMuiTheme(orangeTheme);
				break;
			}
			case PINK: {
				applyTheme = createMuiTheme(pinkTheme);
				break;
			}
			case BLUE: {
				applyTheme = createMuiTheme(blueTheme);
				break;
			}
			case DEEP_PURPLE: {
				applyTheme = createMuiTheme(purpleTheme);
				break;
			}
			case GREEN: {
				applyTheme = createMuiTheme(greenTheme);
				break;
			}
			case DARK_INDIGO: {
				applyTheme = createMuiTheme(indigoTheme);
				break;
			}
			case DARK_CYAN: {
				applyTheme = createMuiTheme(cyanTheme);
				break;
			}
			case DARK_AMBER: {
				applyTheme = createMuiTheme(amberTheme);
				break;
			}
			case DARK_DEEP_ORANGE: {
				applyTheme = createMuiTheme(orangeTheme);
				break;
			}
			case DARK_PINK: {
				applyTheme = createMuiTheme(pinkTheme);
				break;
			}
			case DARK_BLUE: {
				applyTheme = createMuiTheme(blueTheme);
				break;
			}
			case DARK_DEEP_PURPLE: {
				applyTheme = createMuiTheme(purpleTheme);
				break;
			}
			case DARK_GREEN: {
				applyTheme = createMuiTheme(greenTheme);
				break;
			}
			default:
				applyTheme = createMuiTheme(greenTheme);
				break;
		}
		//console.log('THEME',themeColor);
		return applyTheme;
	}
	componentDidMount(){
		const {location} = this.props;
		const url = location.pathname;
		const AITUser = this.getAITUser();
		if(url != null && url !== '' && url !== '/' && AITUser.redirect !== url){
			//Set the redirect URL for the user
			User.setRedirectURL(url);
		}
	}
	checkLoginStatus(){
		const debug = false;
		const AITUser = this.getAITUser();
		if(parseInt(sessionStorage.getItem('resetPasswordComplete')) === 1){
			//User successfully update the password via token - reset the user
			const user = new User();
			user.setClientStorePermissions(false);
			User.setRedirectURL(); //No redirects
			sessionStorage.removeItem('resetPasswordComplete');
			return false;
		}


		if(debug) {
			console.log('>>>>>>>>>>>>>>>>>>>>>>> src/containers/App.js :: CHECKING LOGIN STATUS');
			console.log('>>> LOGGED IN: ', AITUser.loggedIn, AITUser.account);
			if(!isEmpty(AITUser.account.permissions)){
				console.log('>>> Permissions: ', Object.keys(AITUser.account.permissions).length);
			}
		}
		//. Check if AITUser from this.props is not empty (loggedin, and has permissions)
		// AITUser.account.permissions (CAN'T BE EMPTY)
		//.Set AITUser
		if(AITUser.loggedIn && !isEmpty(AITUser.account.permissions)) {
			//User is logged in
			return true;
		} else{
			//No permissions found - check the server if they're already logged in via cookies
			//Only run if pendingComplete (pc) FALSE and pending FALSE (only run once)
			if(AITUser.pending === 0 && AITUser.pc === false) {
				//User.checkSession() will load the session if present
				User.checkSession();
				//setTimeout(User.checkSession(),100); //Delay for 75ms
			}
			return false;
		}
	}
	getAITUser(){
		const userData = User.getUserData();
		return userData != null ? userData : {};
	}
	render() {
		const {match, location} = this.props;
		const loggedIn = this.checkLoginStatus();
		const loginURL ='/sign-in/';
		const resetPasswordURL = '/reset-password/';
		const home = '/my-profile/';
		const AITUser = this.getAITUser();
		if(!loggedIn){
			const atSignIn = location.pathname === loginURL;
			const atReset = location.pathname.includes(resetPasswordURL);
			if(atSignIn === false && atReset === false){ // && !this.state.loginStatusPending
				//console.log('FORWARD TO SIGN IN');
				return ( <Redirect to={loginURL}/> );
			}
		} else{
			//USER IS LOGGED IN=
			//User has requested a separate page (new tab/new window)
			if(AITUser.redirect !== ''){
				const redirect = AITUser.redirect;
				User.setRedirectURL();
				return (<Redirect to={redirect}/>);
			}
			//No redirect - Forward to Profile/homepage.
			if (location.pathname === '/' || location.pathname === loginURL || location.pathname === resetPasswordURL) {
				return (<Redirect to={home}/>);
			}
		}
		//END SIGN-IN

		const {locale, isDirectionRTL, themeColor} = User.getSettings(); //this.props.settings;
		const darkThemeSet = this.props.settings.darkTheme;
		let applyTheme;
		if (darkThemeSet) {
			document.body.classList.add('dark-theme');
			applyTheme = createMuiTheme(darkTheme)
		} else {
			applyTheme = this.getColorTheme(themeColor);
		}
		if (isDirectionRTL) {
			applyTheme.direction = 'rtl';
			document.body.classList.add('rtl')
		} else {
			document.body.classList.remove('rtl');
			applyTheme.direction = 'ltr';
		}
		const currentAppLocale = AppLocale[locale.locale];
		//Sign in url  : `${match.url}sign-in/`
		//reset pass url : `${match.url}reset-password/`
		if(User.getForcedPasswordReset()){
			//forced the password reset
			return (
				<ForcedPasswordReset/>
			)
		}
		return (
			<MuiThemeProvider theme={applyTheme}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<IntlProvider
						locale={currentAppLocale.locale}
						messages={currentAppLocale.messages}>
						<RTL>
							<div className="app-main">
								<Switch>
									<Route exact
									       path={loginURL}
									       component={asyncComponent(() => import('ait/components/System/SignIn'))}
									/>
									<Route exact
									       path={resetPasswordURL}
									       component={asyncComponent(() => import('ait/components/System/ResetPassword'))}
									/>
									<Route path={`${resetPasswordURL}:resetKey`}
									       component={asyncComponent(() => import('ait/components/System/ResetPassword'))}
									/>
									<Route
										path={`${match.url}`}
										component={AITApp}
									/>
									<Route
										component={asyncComponent(() => import('components/Error404'))}
									/>
								</Switch>
							</div>
						</RTL>
					</IntlProvider>
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		);
	}
}

const mapStateToProps = (state) => {
	const {settings} = state;
	return {settings, location: state.router.location}
};
const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);

