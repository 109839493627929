import React from 'react';
import {Grid, Typography, Button, Dialog} from '@material-ui/core';
import {SESSION_TIME_STRING, SESSION_IDLE_MINUTES} from "ait/system/AITConstants";
import {getSpacing} from "ait/system/UtilityFunctions";
import SecureConnect from "ait/system/SecureConnect";
import User from "ait/system/User";

const countdownSeconds = 30; //# of seconds in the countdown warning for the user
const idleTimeCheckInterval = 1; //# of seconds between checks of the last server connection

class UserIdleSessionDialog extends React.Component{
	state ={
		activeTime: '', //utilized by countdown timer to verify that the active time hasnt changed in another tab
		idleLimitReached: false, //displays the modal
		countdown: countdownSeconds
	};
	getActiveTime(){
		return localStorage.getItem(SESSION_TIME_STRING) != null ? parseInt(localStorage.getItem(SESSION_TIME_STRING)) : 0;
	}
	calculateIdleLimitReached(){
		const now =  Math.floor(Date.now() / 1000);
		const activeTime = this.getActiveTime();
		const idleTime = now - activeTime;
		const limitSeconds = SESSION_IDLE_MINUTES * 60;
		const limitReached = idleTime  >= limitSeconds;
		//console.log('Calculating Idle Time : ', idleTime, limitSeconds, limitReached);
		if(this.getLoggedInStatus() && limitReached) {
			this.setState({
				idleLimitReached: limitReached,
				activeTime
			}, ()=>{
				if(limitReached){
					this.startCountdownTimer();
				}
			});
		}
	}
	getLoggedInStatus(){
		const userData = User.getUserData();
		return userData != null && userData.loggedIn === true;
	}
	startIdleTimer(){
		if((this.idleTimer === 0 || this.idleTimer == null) && this.state.idleLimitReached === false){
			this.idleTimer = 0;
			this.idleTimer = setInterval(()=>{
				this.calculateIdleLimitReached();
			}, idleTimeCheckInterval*1000); //How often does the idleTimer check
			//Disable the countdown timer while this idleTimer is running
			this.disableTimer('countdown');
		}
	}
	startCountdownTimer(){
		if(this.state.idleLimitReached === true && this.countdownTimer === 0){
			this.countdownTimer = 0;
			this.countdownTimer = setInterval(()=>{
				this.handleCountdown();
			}, 1000); //Every second
			//Disable the idleTimer while counting down
			this.disableTimer('idle');
		}
	}
	disableTimer(timerType = 'idle'){
		if(timerType === 'idle'){
			clearInterval(this.idleTimer);
			this.idleTimer = 0;
		} else{
			clearInterval(this.countdownTimer);
			this.countdownTimer = 0;
		}
	}
	automaticallyLogOut(){
		let sc = new SecureConnect('system.php?action=autoLogout');
		sc.setUpdateSessionTime(false);
		sc.connect();
		const u = new User();
		u.logout(false);
	}
	handleCountdown(){
		if(this.getLoggedInStatus() === false){
			return;
		}
		const s = this.state;
		let newTime = s.countdown-1;
		if(this.getActiveTime() === 0){
			//Already logged out in another tab. Log the user out
			const u = new User();
			u.logout(false);
		}
		else if(this.getActiveTime() !== s.activeTime){
			//updated in a different state - reset the timer
			this.resetIdleTimer();
		}
		else if(newTime <= 0){
			//Terminate the session
			this.automaticallyLogOut();
		} else{
			//Update the timer for the component
			this.setState({countdown: newTime});
		}
	}
	confirmSessionActive(){
		let sc = new SecureConnect('system.php?action=confirmSessionActive');
		sc.setDisplaySuccessMessages(false);
		sc.connect().then(json=>{
			if(sc.getCompleted(json)){
				this.resetIdleTimer();
			}
		})
	}
	resetIdleTimer(){
		this.setState({
			idleLimitReached: false,
		},()=>{
			this.startIdleTimer();
			setTimeout(()=>{
				this.setState({countdown: countdownSeconds});
			}, 150);
		});
	}
	render(){
		const loggedIn = this.getLoggedInStatus();
		const s = this.state;
		if(!loggedIn){
			this.disableTimer('countdown');
			this.disableTimer('idle');
			return null;
		}
		//Initialize the idleTimer
		this.startIdleTimer();
		return (
			<Dialog
				open={s.idleLimitReached}
				fullWidth={true}
				maxWidth={'sm'}
			>
				<div className={"app-wrapper"}>
					<Grid container spacing={16}>
						<Grid item xs={12}>
							<Typography variant={'h2'}>
								Are You Still There?
							</Typography>
							<Typography variant={'body1'}>
								It seems you've been idle for a while. Please click the button below to confirm you're still present. If you do not click the button soon, the system will automatically log you out.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant={'h1'} align={'center'}>{s.countdown}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Button
								fullWidth
								color={'primary'}
								variant={'contained'}
								style={{marginTop: getSpacing()}}
								onClick={this.confirmSessionActive.bind(this)}
							>I'm Still Here</Button>
						</Grid>
					</Grid>
				</div>
			</Dialog>
		)
	}
}

export default UserIdleSessionDialog;