import React from "react";
import {Grid, Typography, InputAdornment, TextField, Button, IconButton, Tooltip, Collapse} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons/";
import SecureConnect from "ait/system/SecureConnect";
import User from "ait/system/User";
import {getSpacing} from "ait/system/UtilityFunctions";
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import AITAlert from "../AITAlert";

const minimumPasswordLength = 12;
const validationMinWarning = `Password must be at least ${minimumPasswordLength} characters long`;



class PasswordReset extends React.Component{
	state={
		currentPassword: '',
		newPassword: '',
		confirmPassword: '',
		hidePasswords: true,
		resetSuccessful: false,
		forcedReset: User.getForcedPasswordReset()
	};
	handleChange(e){
		const {name, value} = e.target;
		this.setState({[name]: value});
	}
	toggleHidePasswords(){
		this.setState({hidePasswords: !this.state.hidePasswords});
	}
	updatePassword(data){
		let s = this.state;
		let sc = new SecureConnect('user.php','post');
		sc.setAction('changePassword');
		//convert from bool to integer for sending to server
		s.forcedReset = User.getForcedPasswordReset() ? 1: 0;
		sc.setFormData(s);
		sc.connect().then(json=>{
			if(sc.getCompleted(json)){
				//Reset form elements
				if(s.forcedReset === 0) {
					//User wasn't forced to update their password
					//Just update state
					this.setState({
						currentPassword: '',
						newPassword: '',
						confirmPassword: '',
						hidePasswords: true,
						resetSuccessful: true,
						forcedReset: User.getForcedPasswordReset()
					});
				} else{
					//User was forced to reset their password.
					//Log them out of the system to make them log back in
					const u = new User();
					u.logout();
				}
			}
		});
	}
	render(){
		const s = this.state;
		const inputType = s.hidePasswords ? 'password' : 'text';
		const handleChange = this.handleChange.bind(this);
		const tooltipVerb = s.hidePasswords ? 'Show' : 'Hide';
		const hidePasswordToggle= {
			endAdornment:
				<InputAdornment position="end">
					<Tooltip title={`${tooltipVerb} Passwords`} enterDelay={300} placement={'bottom-start'}>
						<IconButton
							aria-label="toggle password visibility"
							onClick={this.toggleHidePasswords.bind(this)}
						>
							{s.hidePasswords ? <Visibility/> : <VisibilityOff/>}
						</IconButton>
					</Tooltip>
				</InputAdornment>
		};
		const buttonStyle = {marginTop: getSpacing()};
		const Caps = new RegExp(/[A-Z]/g);
		const lower = new RegExp(/[a-z]/g);
		const number = new RegExp(/[0-9]/g);
		//Password req checks
		const capCheck = s.newPassword !== '' && s.newPassword.match(Caps) != null;
		const lowCheck = s.newPassword !== '' && s.newPassword.match(lower) != null;
		const numCheck = s.newPassword !== '' && s.newPassword.match(number) != null;
		const specialCheck = s.newPassword !== '' && /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(s.newPassword);
		const passwordResetSchema = yup.object().shape({
			currentPassword: yup.string()
				.trim()
				.required('Your current password is required'),
			newPassword: yup.string()
				.trim()
				.required('A new password is required')
				.min(minimumPasswordLength, validationMinWarning)
				//Capital Letter
				.test(s.newPassword, 'Capital letter required',() => capCheck === true)
				.test(s.newPassword, 'Number required', () => numCheck === true)
				.test(s.newPassword, 'Lower case letter required', ()=> lowCheck === true)
				.test(s.newPassword, 'Special character required', () => specialCheck === true),
			confirmPassword: yup.string()
				.trim()
				.min(minimumPasswordLength, validationMinWarning)
				.test(s.confirmPassword, 'Passwords do not match', value => value === s.newPassword)
				.required('Confirmation of your new password is required'),
		});
		return (
			<Formik
				initialValues={this.state}
				validationSchema={passwordResetSchema}
				onSubmit={this.updatePassword.bind(this)}
				enableReinitialize={true}
			>
				{({ errors, touched}) => (
					<Form>
						<Grid container spacing={8}>
							<Grid item xs={12}>
								<Typography variant="h2">
									Change Your Password
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Collapse in={User.getForcedPasswordReset() === false}>
									<Typography variant={'body1'}>
										Passwords need to be reset every so often to keep your account secure!<br/>
										Now might be a good time to do just that.
									</Typography>
								</Collapse>
							</Grid>
							<Grid item xs={12}>
								<AITAlert
									status={'info'}
									visible={User.getForcedPasswordReset() === true}
									headline={'Password Reset Required'}
									message={"It's that time again! To keep things secure, we need you to reset your password. Once you've reset your password, we'll need you to log back in."}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant={'h4'}>
									Password Requirements
								</Typography>
								<Typography variant={'body1'}>
									<ul>
										<li>Minimum Length: <strong>12</strong> Characters</li>
										<li>At Least <strong>1</strong> Capital Letter</li>
										<li>At Least <strong>1</strong> Lower Case Letter</li>
										<li>At Least <strong>1</strong> Special Character (`!@#$%^&*()_+)</li>
										<li>At Least <strong>1</strong> Number (0123..)</li>
										<li><strong>**Note:** You cannot reuse passwords</strong></li>
									</ul>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									name='currentPassword'
									label='Current Password'
									type={inputType}
									placeholder='Enter Your Current Password'
									value={s.currentPassword}
									onChange={handleChange}
									InputProps={hidePasswordToggle}
									error={errors.currentPassword && touched.currentPassword}
									helperText={errors.currentPassword || ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									name='newPassword'
									label='New Password'
									type={inputType}
									placeholder={`${minimumPasswordLength} Minimum Characters Required`}
									value={s.newPassword}
									onChange={handleChange}
									InputProps={hidePasswordToggle}
									error={errors.newPassword && touched.newPassword}
									helperText={errors.newPassword || ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									name='confirmPassword'
									label='Confirm Your Password'
									type={inputType}
									placeholder='This Must Match Your New Password'
									value={s.confirmPassword}
									onChange={handleChange}
									InputProps={hidePasswordToggle}
									error={errors.confirmPassword && touched.confirmPassword}
									helperText={errors.confirmPassword || ''}
								/>
							</Grid>
							{/*
								<Grid item xs={12}>
									<pre>{JSON.stringify(errors)}</pre>
								</Grid>
							*/}
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									type="submit"
									style={buttonStyle}
								>Change My Password</Button>
							</Grid>
							<Grid item xs={12}>
								<AITAlert
									status={'success'}
									visible={s.resetSuccessful}
									headline={'Password Changed'}
									message={"You've successfully updated your password."}
								/>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>

		)
	}

}

export default PasswordReset;