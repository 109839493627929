import React from 'react';
//import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu';
import {AccountCircle} from "@material-ui/icons";
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from 'react-router-dom';
import {User} from 'ait/system/User';
import {DARK_THEME_MENU_COLOR, LIGHT_THEME_MENU_COLOR} from "ait/system/AITConstants";

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };
  handleRequestClose = () => {
    this.setState({open: false});
  };
  logout(){
  	const user = new User();
  	user.logout();
  }

  render() {
  	const {darkTheme} = this.props;
  	const personName = this.props.name;
  	//Hacky bullshit to get the menu styled.
  	const menuLinkStyle={
	    display: 'block',
	    height: '100%',
	    width: '100%',
	    color: darkTheme ? DARK_THEME_MENU_COLOR : LIGHT_THEME_MENU_COLOR,
	    textDecoration: 'none'
  	};
    return (
      <div className="user-profile d-flex flex-row align-items-center">
	      <AccountCircle style={{width: 42, height: 42}} />
	      {/*
        <Avatar
          alt='...'
          src={'https://via.placeholder.com/150x150'}
          className="user-avatar "
        />
        */}
	      <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>{personName} <i
            className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
          </h4>
        </div>
        <Menu className="user-info"
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              PaperProps={{
                style: {
                  minWidth: 120,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}>
          <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
	          <Link to={'/my-profile/'} style={menuLinkStyle}>Profile</Link>
          </MenuItem>

	       {
          <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
	          <Link to={'/settings/'} style={menuLinkStyle}>Settings</Link>
          </MenuItem>
          }


	        <MenuItem onClick={() => {
            this.handleRequestClose();
          }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

	          <span onClick={this.logout.bind(this)}>Logout</span>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default UserInfo;


