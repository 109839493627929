import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

//ORIGINAL CODE
import {history} from './store';
import App from './containers/App';
import ClientStore from 'ait/system/ClientStore';

const MainApp = () =>
  <Provider store={ClientStore}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>;

export default MainApp;