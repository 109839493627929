import SecureConnect from './SecureConnect';
import {ACTOR_DEPT_ID, ACCOUNTING_DEPT_ID, ADMIN_DEPT_ID, PRIVATE_DEPT_ID, PRODUCTION_DEPT_ID, TECH_DEPT_ID, LOGISTICS_DEPT_ID} from "./AITConstants";

const showIcon = 'zmdi-star'; //For the differnet show links
const sc = new SecureConnect();
const apiURL = sc.getURL();

export const RoutePermissions = [
	{
		name: 'Admin',
		deptID: ADMIN_DEPT_ID,
		routes: [
			{
				name: 'God Tier Users',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/admin/god-tier-users/',
				component:import('ait/routes/admin/SuperUserDirectory'),
				iconClasses: 'zmdi-flash',
			},
			{
				name: 'Users',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/admin/users/',
				component:import('ait/routes/shared/UserList'),
				iconClasses: 'zmdi-account',
			},
			{
				name: 'User Log',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/admin/user-history/',
				component:import('ait/routes/admin/UserHistory'),
				iconClasses: 'zmdi-zoom-in',
			},
		]
	},
	{
		name: 'Private',
		deptID: PRIVATE_DEPT_ID,
		routes: [
			{
				name: 'Current Points',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/private-sales/current-points/',
				component:import('ait/routes/private/CurrentPoints'),
				iconClasses: 'zmdi-plus-square',
			},
			{
				name: 'Company Points',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 2,
				route: '/private-sales/company-points/',
				component:import('ait/routes/private/CompanyPoints'),
				iconClasses: 'zmdi-plus-square',
			},
			{
				name: 'Customers',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/private-sales/customer-list/',
				component:import('ait/routes/private/CustomerList'),
				iconClasses: 'zmdi-account',
			},
			{
				name: 'Customer Notifications',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/private-sales/customer-notifications/',
				component: import('ait/routes/private/NotificationList'),
				iconClasses: 'zmdi-email',
			},
			{
				name: 'Private Sales Show List',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/private-sales/show-list/',
				component:import('ait/routes/private/ShowList'),
				iconClasses: showIcon,
			},
			{
				name: 'Lead Tracking',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/private-sales/lead-tracking/',
				component: import('ait/routes/private/LeadTracking'),
				iconClasses: 'zmdi-view-dashboard',
			},
			// {
			// 	name: 'Detailed Lead Tracking',
			// 	menuDisplay: true,
			// 	subMenu: false,
			// 	managerLevel: 0,
			// 	route: '/private-sales/lead-tracking-details/',
			// 	component: import('ait/routes/private/LeadTrackingDetails'),
			// 	iconClasses: 'zmdi-view-dashboard',
			// },
			{
				name: 'New Phone Lead',
				menuDisplay: false,
				subMenu: false,
				managerLevel: 0,
				route: '/private-sales/new-phone-lead/',
				component:import('ait/routes/private/NewPhoneLead'),
				iconClasses: showIcon,
			},
			{
				name: 'Manager',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 1,
				route: '',
				iconClasses: 'zmdi-lock',
				children: [
					{
						name: 'Blank Contract',
						external: true,
						menuDisplay: true,
						subMenu: false,
						managerLevel: 1,
						route: `${apiURL}privatesales.php?action=getBlankContract`,
						component: import('ait/routes/private/CompanyPoints'),
						iconClasses: 'zmdi-file-text',
					},
					{
						name: 'Sales Rep Report',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 1,
						route: '/private-sales/sales-rep-report/',
						component: import('ait/routes/private/ManagerRepReport'),
						iconClasses: '',
					},
					{
						name: 'Users',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 1,
						route: '/private-sales/users/',
						component:import('ait/routes/shared/UserList'),
						iconClasses: '',
					},
				]
			},
		],
	},
	{
		name: 'Production',
		deptID: PRODUCTION_DEPT_ID,
		routes: [
			{
				name: 'Casting Conflicts',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/production/actor-casting-conflicts/',
				component:import('ait/routes/production/ActorCastingConflicts'),
				iconClasses: 'zmdi-check-square',
			},
			{
				name: 'Co Point Management',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 2,
				route: '/production/co-point-management/',
				component:import('ait/routes/production/CompanyPointsManagement'),
				iconClasses: 'zmdi-plus-square',
			},
			{
				name: 'Director Hours Review',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/finance/director-pay-review/',
				component:import('ait/routes/finance/DirectorHourlyPayReview'),
				iconClasses: 'zmdi-time',
			},
			{
				name: 'Pay Disputes',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/production/payment-disputes/',
				component:import('ait/routes/production/PaymentDisputes'),
				iconClasses: 'zmdi-money',
			},
			{
				name: 'Show Management',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				route: '',
				iconClasses: showIcon,
				children: [
					{
						name: 'Blackout Dates',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/blackout-dates/',
						component:import('ait/routes/production/BlackoutDates'),
						iconClasses: '',
					},
					{
						name: 'Show Calendar',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/show-calendar/',
						component:import('ait/routes/production/ShowCalendar'),
						iconClasses: '',
					},
					{
						name: 'Show List',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/show-list/',
						component:import('ait/routes/production/ShowList'),
						iconClasses: '',
					},
					{
						name: 'Travel Show List',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/travel-show-list/',
						component:import('ait/routes/production/TravelShowList'),
						iconClasses: '',
					},
					{
						name: 'Shows Projected',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/shows-projected/',
						component:import('ait/routes/production/ShowsProjected'),
						iconClasses: '',
					},

				]
			},
			{
				name: 'Surveys',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				route: '',
				iconClasses: 'zmdi-comment-more',
				children: [
					{
						name: 'Survey List',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/survey-list/',
						component:import('ait/routes/production/SurveyList'),
						iconClasses: 'zmdi-check-square',
					},
					{
						name: 'Actor Results',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/survey-actors/',
						component:import('ait/routes/production/SurveyActors'),
						iconClasses: '',
					},
					{
						name: 'Show Results',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/survey-private-shows/',
						component:import('ait/routes/production/SurveyPrivateShows'),
						iconClasses: '',
					}
				]
			},
			// {
			// 	name: 'News',
			// 	menuDisplay: true,
			// 	subMenu: true,
			// 	managerLevel: 0,
			// 	route: '',
			// 	iconClasses: 'zmdi-format-subject',
			// 	children: [
			// 		{
			// 			name: 'Add News',
			// 			menuDisplay: true,
			// 			subMenu: false,
			// 			managerLevel: 0,
			// 			route: '/production/add-news/',
			// 			component:import('ait/routes/production/NewsAdd'),
			// 			iconClasses: 'zmdi-check-square',
			// 		},
			// 		{
			// 			name: 'Edit News',
			// 			menuDisplay: true,
			// 			subMenu: false,
			// 			managerLevel: 0,
			// 			route: '/production/edit-news/',
			// 			component:import('ait/routes/production/NewsEdit'),
			// 			iconClasses: '',
			// 		}
			// 	]
			// },
			{
				name: 'Survey Results',
				menuDisplay: false,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				route: '/production/survey/:surveyID/',
				component:import('ait/routes/production/SurveySpecific'),
				iconClasses: 'zmdi-comment-more',
			},
			{
				name: 'Travel Shows',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				route: '',
				iconClasses: 'zmdi-airplane',
				children: [
					{
						name: 'Actor Travel Pay',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/actor-travel-pay/',
						component:import('ait/routes/production/ActorTravelPay'),
						iconClasses: '',
					},
					{
						name: 'Travel Show List',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/travel-show-list/',
						component:import('ait/routes/production/TravelShowList'),
						iconClasses: '',
					}
				]
			},
			{
				name: 'Troupes',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				iconClasses: 'zmdi-account-box',
				children: [
					{
						name: 'Manage Troupes',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/troupe-manager/',
						component:import('ait/routes/production/TroupeManager'),
						iconClasses: '',
					},
					{
						name: 'Troupe Directory',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/troupe-list/',
						component:import('ait/routes/production/TroupeDirectory'),
						iconClasses: '',
					},
					{
						name: 'Troupe Profitability',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/troupe-profitability/',
						component:import('ait/routes/production/TroupeProfitability'),
						iconClasses: '',
					},
					{
						name: 'Themes Report',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/troupe-themes/',
						component:import('ait/routes/production/TroupeThemeReport'),
						iconClasses: '',
					}
				]
			}
		]
	},
	{
		name: 'Finance',
		deptID: ACCOUNTING_DEPT_ID,
		routes: [
			{
				name: 'Show Payments',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				route: '',
				iconClasses: 'zmdi-money',
				children: [
					{
						name: 'Payment Calendar',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/finance/payment-calendar/',
						component: import('ait/routes/finance/PaymentCalendar'),
						iconClasses: '',
					},
					{
						name: 'Show Payment List',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/finance/payment-list/',
						component: import('ait/routes/finance/PaymentList'),
						iconClasses: '',
					},
					{
						name: 'Refunds',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/finance/show-refunds/',
						component: import('ait/routes/finance/ShowRefunds'),
						iconClasses: '',
					},
				]
			},
			{
				name: 'Show List',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/finance/show-list/',
				component:import('ait/routes/production/ShowList'),
				iconClasses: showIcon,
			},
			{
				name: 'Troupe Directory',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/finance/troupe-list/',
				component:import('ait/routes/production/TroupeDirectory'),
				iconClasses: 'zmdi-account-box',
			},
			{
				name: 'Actor Pay',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				route: '',
				iconClasses: 'zmdi zmdi-money-box',
				children: [
					{
						name: 'Payment Ledger',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/finance/ledger/',
						component:import('ait/routes/finance/ActorPayLedger'),
						iconClasses: '',
					},
					/*
					//Disabled 2020-10-19 Using Formstack per Scott/Mae
					{
						name: 'Actor Docs: W9/DD',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 1,
						route: '/finance/actor-payment-documents/',
						component:import('ait/routes/finance/ActorPaymentDocuments'),
						iconClasses: '',
					}
					 */
				]
			},
			{
				name: 'Deposit Submissions',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/finance/director-deposit-submissions/',
				component:import('ait/routes/finance/DirectorDepositSubmissions'),
				iconClasses: 'zmdi-assignment-check',
			},
			{
				name: 'Photographer Sales',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/finance/photographer-sales/',
				component:import('ait/routes/finance/PhotographerSales'),
				iconClasses: 'zmdi-camera',
			},
			{
				name: 'Public Venues',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/finance/public-venues/',
				component:import('ait/routes/finance/PublicVenues'),
				iconClasses: 'zmdi-cutlery',
			},
			{
				name: 'Public Venue Profile',
				menuDisplay: false,
				subMenu: false,
				managerLevel: 0,
				route: '/finance/public-venues/:venueID/',
				component:import('ait/routes/finance/PubVenueProfile'),
				iconClasses: 'zmdi-cutlery',
			},
		]
	},
	{
		name: 'Tech',
		deptID: TECH_DEPT_ID,
		routes: [
			{
				name: 'No Show Email List',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				route: '/tech/no-shows-email-list/',
				component: import('ait/routes/tech/CustomerNoShows'),
				iconClasses: 'zmdi-email',
			}
		]
	},
	{
		name: 'Logistics',
		deptID: LOGISTICS_DEPT_ID,
		routes: [
			{
				name: 'Logistics Bins',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				route: '/logistics/show-theme-table/',
				component: import('ait/routes/logistics/ShowThemeTable'),
				iconClasses: 'zmdi-email',
			}
		]
	},
	{
		name: 'Acting',
		deptID: ACTOR_DEPT_ID,
		routes: [
			{
				name: 'Actor Setup',
				menuDisplay: false,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				route: '/acting/setup/',
				component:import('ait/routes/acting/ActorSetup'),
				iconClasses: showIcon,
			},
			{
				name: 'Show Hub',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				route: '/acting/',
				component:import('ait/routes/acting/ActorHome'),
				iconClasses: showIcon,
			},
			{
				name: 'My Profile',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				route: '/my-profile/',
				component:import('ait/routes/shared/MyProfile/'),
				iconClasses: 'zmdi-account-box',
			},
			{
				name: 'Troupe Directory',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				route: '/acting/troupe-list/',
				component:import('ait/routes/acting/ActorTroupeDirectory'),
				iconClasses: 'zmdi-dialpad',
			},
			{
				name: 'Survey',
				menuDisplay: true,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				route: '/acting/actor-survey/',
				component:import('ait/routes/acting/SurveyForm'),
				iconClasses: 'zmdi-comment-more',
			},
			{
				name: 'Actor Show Details',
				menuDisplay: false,
				subMenu: false,
				managerLevel: 0,
				directorLevel: 0,
				route: '/acting/show/:showID/',
				component:import('ait/routes/acting/ShowDetails'),
				iconClasses: 'zmdi-comment-more',
			},

			{
				name: 'Director Interfaces',
				menuDisplay: true,
				subMenu: true,
				managerLevel: 0,
				directorLevel: 2,
				route: '',
				component:import('ait/routes/acting/SurveyForm'),
				iconClasses: 'zmdi-lock',
				children: [
					{
						name: 'Show Surveys',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						route: '/production/survey-private-shows/',
						component:import('ait/routes/production/SurveyPrivateShows'),
						iconClasses: '',
					},
					{
						name: 'Show Calendar',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						directorLevel: 2,
						directorStrict: false,
						route: '/acting/director/show-calendar/',
						component:import('ait/routes/acting/ShowCalendar'),
						iconClasses: '',
					},
					{
						name: 'Submit Deposit',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						directorLevel: 2,
						directorStrict: true,
						route: '/acting/director/submit-deposit/',
						component:import('ait/routes/acting/SubmitDeposit'),
						iconClasses: '',
					},
					{
						name: 'Submit Hours',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						directorLevel: 2,
						directorStrict: true, //LIMITED TO DIRECTORS (NOT REGIONAL DIRECTORS ONLY)
						route: '/acting/director/submit-hours/',
						component:import('ait/routes/acting/SubmitHours'),
						iconClasses: '',
					},
					{
						name: 'Troupe Directory',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						directorLevel: 2,
						route: '/acting/director/troupe-list/',
						component:import('ait/routes/production/TroupeDirectory'),
						iconClasses: '',
					},
					{
						name: 'Order Form',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						external: true,
						route: 'https://docs.google.com/forms/d/e/1FAIpQLSdSSxoRZ9Vl8vfDVaudoSiHo4azDC3OtH03-bob_kBE8rbG-Q/viewform',
						// component:import('ait/routes/production/TroupeThemeReport'),
						iconClasses: '',
					},
					{
						name: 'Logistics Bins',
						menuDisplay: true,
						subMenu: false,
						managerLevel: 0,
						directorLevel: 2,
						directorStrict: true,
						route: '/logistics/show-theme-table/',
						component: import('ait/routes/logistics/ShowThemeTable'),
						iconClasses: '',
					}
				]
			}
		]
	}
];

//Routes shared by multiple departments
export const SharedPermissions =[
	{
		name: 'Customer',
		deptIDs: [[2,0],[3,0],[4,0]],
		menuDisplay: false,
		subMenu: false,
		exactMatch: false,
		route: '/customer/:customerID/',
		component: import('ait/routes/shared/Customer')
	},
	{
		name: 'Payment',
		deptIDs: [[2,0],[4,0]],
		menuDisplay: false,
		subMenu: false,
		exactMatch: false,
		route: '/payments/:paymentID/',
		component: import('ait/routes/shared/Payment')
	},
	{
		name: 'Payment (FULL)',
		deptIDs: [[2,0],[4,0]],
		menuDisplay: false,
		subMenu: false,
		exactMatch: false,
		route: '/payments/full/:showID/',
		component: import('ait/routes/shared/Payment')
	},
	{
		name: 'My Profile',
		deptIDs: [[2,0],[3,0],[4,0],[6,0]],
		menuDisplay: false,
		subMenu: false,
		managerLevel: 0,
		route: '/my-profile/',
		component:import('ait/routes/shared/MyProfile/'),
		iconClasses: 'zmdi-account-box',
	},
	{
		name: 'User Profile',
		deptIDs: [[2,1],[3,0],[4,0],[6,0]],
		menuDisplay: false,
		subMenu: false,
		exactMatch: false,
		route: '/profile/:userID/',
		component: import('ait/routes/shared/User')
	},
	{
		name: 'Show',
		deptIDs: [[2,0],[3,0],[4,0]],
		menuDisplay: false,
		subMenu: false,
		exactMatch: false,
		managerLevel: 0,
		route: '/show/:showID/',
		component: import('ait/routes/shared/Show')
	},
	{
		name: 'Resources',
		deptIDs: [[2,0],[3,0],[4,0],[6,0]],
		menuDisplay: true,
		subMenu: false,
		route: '/resources/',
		iconClasses: 'zmdi-globe-alt',
		component: import('ait/routes/shared/Resources')
	},
	{
		name: 'Settings',
		deptIDs: [[2,0],[3,0],[4,0],[6,0]],
		menuDisplay: false,
		subMenu: false,
		route: '/settings/',
		iconClasses: 'zmdi-globe-alt',
		component: import('ait/routes/shared/Settings')
	},
	{
		name: 'Logistics Bins',
		deptIDs: [[9,0],[3,0],[6,2]],
		menuDisplay: true,
		subMenu: false,
		route: '/logistics/show-theme-table/',
		component: import('ait/routes/logistics/ShowThemeTable'),
		iconClasses: 'zmdi-email'
	}

];
