import deepPurple from '@material-ui/core/colors/deepPurple';
import amber from '@material-ui/core/colors/amber';

export default {
  palette: {
    primary: {
      light: deepPurple[300],
      main: deepPurple[500],
      dark: deepPurple[700],
      contrastText: '#fff'
    },
    secondary: {
      light: amber[300],
      main: amber['A700'],
      dark: amber[700],
      contrastText: '#fff'
    },
	statuses: {
	  success: '#3bcc00',
	  info: '#00aacc',
	  warning: '#e59900',
	  error: '#e53a36',
	  noAction: '#d9d9d9',
	},
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
};
