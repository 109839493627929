/*
	AIT USER REDUCER FOR MANAGING USER SETTINGS
*/

const startingUserSettings = {
	loggedIn: false, //is logged in
	pending: 0, //pending session check (0=> not checked, 1=>in progress, 2=>Complete)
	redirect: '',
	pc: false,
	account: {}, //AIT User Info (Settings/Permissions)
};


const AITUser = (state = startingUserSettings, action) =>{
	switch(action.type){
		case 'UPDATE_ACTOR_CHECK':
			//Update actor checks (w9, dd, noncompete, phoneConfirmed) when onboarding
			let {account} = state;
			account.actorChecks[action.setting] = action.value;
			return {
				...state,
				account
			};
		case 'UPDATE_USER':
			//Update user permissions occurs when user account is acted on
			return {
				...state,
				loggedIn: action.authorized,
				pending: 0,
				pc: true, //pending complete
				account: action.userInfo,
			};
		case 'UPDATE_USER_DETAILS':
			//Update user details (name, phone, email, mailing address) when user edits their own account
			return {
				...state,
				account: action.userInfo
			};
		case 'LOGOUT':
			return {
				...state,
				loggedIn: false,
				pending: 2,
				pc: true,
				account: {}
			};
		case 'LOCK_SCREEN':
			return {
				...state,
				loggedIn: false,
				pending: 2,
				pc: true,
			};
		case 'SET_PENDING':
			let pcStatus = action.pendingStatus > 1 ;
			return {
				...state,
				loggedIn: false,
				pc: pcStatus,
				pending: action.pendingStatus,
			};
		case 'SET_REDIRECT_URL':
			return {
				...state,
				redirect: action.url
			};
		default:
			return state;
	}
};

export default AITUser;
