import React from 'react';
import {CircularProgress, Paper} from '@material-ui/core';
import {NotificationContainer} from 'react-notifications';
import PasswordReset from "ait/components/User/PasswordReset";
import {setPageTitle, getSpacing} from 'ait/system/UtilityFunctions';

class ForcedPasswordReset extends React.Component{
	render(){
		setPageTitle("It's Time To Reset Your Password");
		const loader = false;
		const paperStyle = {padding: getSpacing()};
		return(
			<div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
				<Paper style={paperStyle}>
					<PasswordReset/>
				</Paper>
				{
					loader &&
					<div className="loader-view">
						{<CircularProgress/>}
					</div>
				}
				{/*showMessage && NotificationManager.error(alertMessage) */}
				{<NotificationContainer/>}
			</div>
		);
	}
}

export default ForcedPasswordReset;