import User from 'ait/system/User';
import {NotificationManager} from 'react-notifications';
import {isEmpty} from "./UtilityFunctions";
import {API_ENDPOINT, SESSION_TOKEN_KEY, SESSION_TIME_STRING} from "./AITConstants";
/*
	-PROTOTYPE FOR MAKING SECURE CONNECTIONS TO THE SERVER
	-Receive url, method, formAction, outputNotifications, outputSuccessMessages
		-OutputNotifications: true/false (hide all messages - default should be true)
		-OutputSuccessMessages: true/false (allow for hiding of success messages - e.g. displaying tables)
	-Receive response from server
		-0. Check permission is allowed. If denied. Shut the whole thing down
		-1. Check any userdata provided. If so, update user interface
		-2. Display notifications depending on notifications setup
		-3. Output data to component/state as necessary
*/
const serverFailedMessage = 'Something went wrong. Please submit a bug.';

export class SecureConnect {
	constructor(endpoint, method = 'get'){
		this.url = API_ENDPOINT;
		this.endpoint = endpoint;
		this.method = method;
		this.displayNotifications = true;
		this.displaySuccessMessages = true;
		this.receiveFile = false;
		this.logConsole = false;
		this.action = '';
		this.headers = {};
		this.denyAccessCode = 401; //Response code that will automatically log a user out
		this.formData = {};
		this.tokenKey = SESSION_TOKEN_KEY;
		this.updateSessionTime = true;
		//console.log('created connection class');console.log(url);
	}
	setAction(action){
		//Used for Posting to an endpoint
		this.action = action;
	}
	setDisplayNotifications(bool){
		//Determines whether notifications should be displayed
		this.displayNotifications = bool;
	}
	setDisplaySuccessMessages(bool){
		//Determines whether success notifications should be displayed
		this.displaySuccessMessages = Boolean(bool);
		if(bool) {
			//Automatically display notifications because success messages are getting displayed
			this.displayNotifications = Boolean(bool);
		}
	}
	setUpdateSessionTime(bool){
		this.updateSessionTime = Boolean(bool);
	}
	setFormData(formData){
		this.formData = formData;
	}
	setReceiveFile(bool){
		this.receiveFile = Boolean(bool);
	}
	getBodyString(){
		const token = User.getSessionToken();
		let bodyString = null;
		this.headers = {};
		if(Object.keys(this.formData).length > 0){
			this.formData[this.tokenKey] = token;
			this.formData['action'] = this.action;
			bodyString = '?' + Object.keys(this.formData).map(e =>
				`&${e}=${this.formData[e]}`
			).join('');
			let data = this.formData;
			data.action = this.action;
			bodyString = new URLSearchParams(this.formData);
			if(this.method === 'post') {
				this.headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'};
			}
		}
		/*
		 let formDataValues = `?action=${tf.action}` + Object.keys(this.state.tableInputs).map(e =>
		 `&${e}=${this.state.tableInputs[e]}`
		 ).join('');

		*/
		return bodyString;
	}
	async transmit(){
		let data = this.connect().then(json =>{
			this.manageResponse(json);
			this.manageUserData(json);
		});
		return data;
	}
	async connect() {
		let body = this.getBodyString();
		let url = this.getEndPoint();
		if(this.method === 'get' && body != null){
			//When parameters are passed via formData rather than url string
			url = `${url}?${body}`;
			body = null;
		}
		if(this.method === 'get' && url.includes(`&${this.tokenKey}=`) === false){
			//Add in token for _GET requests
			url = `${url}&${this.tokenKey}=${User.getSessionToken()}`
		}
		try {
			if(this.logConsole) {
				console.log('SECURE CONNECT: CONNECTING TO :: ', url);
			}
			if(this.receiveFile === false) {
				let data = await (await fetch(url, {
					body: body,
					method: this.method,
					headers: this.headers
				}))
					.json()
					.then(data => this.manageUserData(data))
					.then(data => {
						this.manageServerResponse(data);
						return data;
					});
				//Set the sessionTimeStamp for handling idling of the user
				this.setSessionTimeStamp();
				return data;
			} else{
				//RECEIVING THE FILE
				fetch(url, {
					body: body,
					method: this.method,
					headers: this.headers
				}).then(res => res.blob())
					.then(blob =>{
						console.log(blob)
					})
			}
		} catch (e) {
			if(this.logConsole) {
				console.log('-----------------ERROR-----------------');
				console.log('%c' + e, 'font-size: 14px; color:red; font-weight: bold;');
				console.log('-----------------ERROR-----------------');
			}
			if(url.includes('system.php?action=authUser') === false){
				//Hacky shit - doesn't register json on authUser. weird.
				this.createReactNotification(4,'Server Connection Error',serverFailedMessage);
			}
			return this.clientSideError();
		}
	}
	manageServerResponse(json) {
		const response = json.response;
		const outputCodeLists = [2, 3, 4, 401];
		const serverResponseCode = parseInt(response.code);
		//0. If logged out/session terminated: set login form as interface
		const consoleList = [
			{style: 'font-size: 14px; color: red; font-weight: bold; background: #ffcccc;'}, //Error
			{style: 'font-size: 14px; color: white; font-weight: normal; background: #00CD1D;'}, //Success
			{style: 'font-size: 14px; color: white; font-weight: bold; background: #00CCFF;'}, //INFO
			{style: 'font-size: 14px; color: #FF5500; font-weight: bold; background: #FF0;'}, //WARNING
			{style: 'font-size: 14px; color: red; font-weight: bold; background: #ffcccc;'}, //ERROR
		];
		if (this.logConsole) {
			console.log('---------------------------------');
			console.log(response);
			console.log('---------------------------------');
		}



		//this.createReactNotification(serverResponseCode,response.msg);
		//Handle responses & errors
		//1. IF error connecting to server, display error (regardless of displayNotifications)
		//2. Based on code determine which kind of message to display
		//-Code 401 = Unauthorized Access Handle Separately
		//If. Code = 1 = success message. Display message
		if(this.displayNotifications && (
				(this.displaySuccessMessages && serverResponseCode === 1) ||
				(serverResponseCode !== 1 && outputCodeLists.filter(code => (code === serverResponseCode)))
			)){
				const style = consoleList[serverResponseCode].style;
				if(this.logConsole) {
					console.log('%c' + response.msg, style);
				}
				this.createReactNotification(serverResponseCode, response.codeText, response.msg);
		}
	}
	manageUserData(json){
		const user = new User();
		if(this.getResponseCode(json) === this.denyAccessCode){
			//Access Denied. Log the user out.
			user.setClientStorePermissions(false, {}); //Log the user out
			this.terminateSessionTimestamp();
			return;
		}
		if(json.userData){ //userData provided - update permissions
			/*
			console.log('======================================');
			console.info('USER DATA FOUND');
			console.log(json.userData);
			console.log('======================================');
			*/
			const authorized = json.userData.permissions != null && !isEmpty(json.userData.permissions);
			//update user data in state
			user.setClientStorePermissions(authorized, json.userData);
			delete json.userData;
		}
		return json;
	}
	setSessionTimeStamp(){
		if(this.updateSessionTime) {
			const now = Math.floor(Date.now() / 1000);
			localStorage.setItem(SESSION_TIME_STRING, now);
		}
	}
	terminateSessionTimestamp(){
		localStorage.removeItem(SESSION_TIME_STRING);
	}
	getURL(){
		return this.url;
	}
	getEndPoint(){
		return `${this.url}${this.endpoint}`;
	}
	getCompleted(json){
		//return bool if action was completed
		if(this.logConsole) {
			console.log(json);
		}
		return this.checkResponse(json) || json.response.completed === 1;
	}
	clientSideError(){
		//return a generic error if client cannot contact the server
		//console.log('SECURECONNECT.JS :: RETURNING DEFAULT ERROR');
		return {
			completed: 0,
			code: 0,
			codeText: 'Error',
			msg: '#CSJS4506 Unable to contact server.',
			msgTypeClass: 'alert-error',
			rowsAffected: 0
		};
	}
	createReactNotification(code, codeText, message, delay = 3){
		const delaySeconds = delay*1000;
		const codeAssignments = {
			0: 'warning',
			1: 'success',
			2: 'info',
			3: 'warning',
			4: 'error',
			401: 'error'
		};
		const type = codeAssignments[code];
		if(this.logConsole) {
			console.log({code, codeText, message});
			console.log('TYPE = ', type);
		}
		switch(type){
			case 'info':
				NotificationManager.info(message, codeText, delaySeconds);
				break;
			case 'success':
				NotificationManager.success(message, codeText, delaySeconds);
				break;
			case 'warning':
				NotificationManager.warning(message, codeText, delaySeconds);
				break;
			case 'error':
				NotificationManager.error(message, codeText, delaySeconds);
				/*
				NotificationManager.error(<IntlMessages id="notification.errorMessage"/>, <IntlMessages
					id="notification.clickMe"/>, delaySeconds, () => {
					alert('callback');
				});
				*/
				break;
			default: NotificationManager.warning(message);
		}
	}
	getResponse(json){
		//Return the response section of the server JSON response
		if(this.checkResponse(json)) {
			return json.response;
		} else{
			console.error('Connection: invalid response. Sorry. Blame the malformed response.');
			return this.clientSideError();
		}
	}
	getData(json){
		//Return the data section of the server JSON response
		if('data' in json) {
			return json.data.dataSet;
		} else {
			//Nothing to return empty set (nothing)
			return {}; //null;
		}
	}
	getResponseCode(json){
		//Return responseCode or errorCode (4)
		return this.checkResponse(json) ? parseInt(json.response.code) : 4;
	}
	getResponseMessage(json){
		return json.response != null && json.response.msg != null ? json.response.msg : serverFailedMessage;
	}
	checkResponse(json){
		if (json != null 
			&& json.response != null
			&& json.response.completed != null) {
				return json != null 
				&& json.response != null
				&& json.response.completed != null;
			} else {
				return json;
			}
		// return json != null 
		// && json.response != null
		//  // && json.response.code != null;
		// && json.response.completed != null;
	}
}

export default SecureConnect;
