import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import {buildNavList} from 'ait/system/RoutePermissions';

class SidenavContent extends Component {
  state ={
  	submenu: false
  };
  setMenu(){
	  const {history} = this.props;
	  const that = this;
	  const pathname = `${history.location.pathname}`;// get current path
	  const menuLi = document.querySelectorAll('li.menu');
	  for (let i = 0; i < menuLi.length; i++) {
		  menuLi[i].onclick = function (event) {
			  for (let j = 0; j < menuLi.length; j++) {
				  const parentLi = that.closest(this, 'li');
				  if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
					  menuLi[j].classList.remove('open')
				  }
			  }
			  this.classList.add('open');
		  }
	  }
	  const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
	  try {
		  const activeNav = this.closest(activeLi, 'ul'); // select closest ul
		  if (activeNav.classList.contains('sub-menu')) {
			  this.closest(activeNav, 'li').classList.add('open');
		  } else {
			  this.closest(activeLi, 'li').classList.add('open');
		  }
	  } catch (error) {

	  }
  }
  componentDidUpdate() {
    this.setMenu();
  }
  activateSubMenus(){
  	/*
  	    .This is some hacky shit.
  	    .Sub menu's won't load (using the query selectors until after the component updates and the permitted menu lists are rendered
  	    .render() calls this function to update state
  	    .updating state updates the component
  	    .activates the submenu
  	    .so I can move onto other misc shit
  	*/
  	if(!this.state.submenu){
  		this.setState({submenu: true});
    }
  }
  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] == 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }
    return null;
  }
  render() {
  	this.activateSubMenus();
  	const navList = buildNavList('nav');
    return (
	  <CustomScrollbars className=" scrollbar">
		  <ul className="nav-menu">
			  {navList}
		  </ul>
	  </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);

