import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {AppBar, Toolbar, IconButton, Tooltip}  from '@material-ui/core';
import {Close} from "@material-ui/icons";
import User from "ait/system/User";
import {
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER
} from 'constants/ActionTypes';
import {switchLanguage, toggleCollapsedNav} from 'actions/Setting';

import Menu from 'components/TopNav/Menu';

class Header extends React.Component {
  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification
    })
  };
  logout(){
  	const user = new User();
  	user.logout();
  }
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification
    })
  };
  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
    })
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    })
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps
    })
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  };
  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false
    });
  };
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };
  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
    }
  }
  render() {
const {drawerType, navigationStyle, horizontalNavPosition} = this.props;
const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

	// className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}
	return (
		<AppBar
			color={'primary'}
			className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}
		>
			<Toolbar className="app-toolbar" disableGutters={false}>
				{navigationStyle === HORIZONTAL_NAVIGATION ?
					<div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
						<span className="jr-menu-icon">
						<span className="menu-icon"/>
						</span>
					</div>
					:
					<IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
						onClick={this.onToggleCollapsedNav}>
						<span className="menu-icon"/>
					</IconButton>
				}

				<Link className="app-logo mr-2 d-none d-sm-block" to="/">
					<img src={require("assets/images/aitLogo.png")} alt="American Immersion Theater" title="American Immersion Theater"/>
				</Link>
				{(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&  <Menu/>}
				<div style={{marginLeft: 'auto'}}>
         For any Emergency needs or to contact the Production Department,
          call <b>616-454-9140</b>
					<Tooltip title={'Logout'} placement={'bottom-start'} enterDelay={300}>
						<IconButton color={'inherit'} onClick={this.logout.bind(this)}>
							<Close/>
						</IconButton>
					</Tooltip>
				</div>
			</Toolbar>
		</AppBar>
		);
	}

}


const mapStateToProps = ({settings}) => {
  const {drawerType, locale, navigationStyle, horizontalNavPosition} = settings;
  return {drawerType, locale, navigationStyle, horizontalNavPosition}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, switchLanguage})(Header));
