import cyan from "@material-ui/core/colors/cyan";
import pink from "@material-ui/core/colors/pink";

const mainPrimary = cyan[500];
const mainSecondary = pink[500];

export default {
  palette: {
    type: 'dark',
    types: {
      dark: {
        background: {
          paper: '#2d353c',
          default: '#2d353c',
          appBar: '#2d353c',
          contentFrame: '#2d353c',
          chip: '#2d353c',
          avatar: '#2d353c'
        }
      }
    },
    primary: {
      light: cyan[300],
      main: mainPrimary, /*grey[700],*/
      dark: cyan[700],
      contrastText: '#fff'
    },
    secondary: {
      light: pink[200],
      main: mainSecondary,
      dark: pink[700],
      contrastText: '#fff'
    },
	statuses: {
	  success: '#248000',
	  info: '#006a80',
	  warning: '#805500',
	  error: '#ba2d27',
	  noAction: '#595959',
	},
    background: {
      paper: '#2d353c',
      default: '#2d353c',
      appBar: '#2d353c',
      contentFrame: '#2d353c',
      chip: '#2d353c',
      avatar: '#2d353c'
    }
  },
  status: {
    danger: 'orange',
  },

  typography: {
    button: {
      fontWeight: 400,
      textAlign:
        'capitalize'
    },
  },
};
