/*
	SUMMARY
	-This is a list of route permissions.
	-Route permissions are determined based on department (and potentially troupe assignments)
	-This object builds the navlist and the rules for react router

	OBJECTS
	-RoutePermissions & SharedPermissions are found in the ./RouteLists.js file
	-RoutePermissions are department specific permissions
	-SharePermissions are shared routes used by multiple departments (eg. customer interface, show edit form, resources, profile)

	DATA STRUCTURE
	- []
		=> Department {name, deptID, Routes[
			=> Name (Menu Node Text)
			=> Title (Title for the page)
			=> menuDisplay (bool) - display in the menu or not
			=> submenu (bool)(Has menu children)
			=> Route => route to the page
			=> iconClasses = '' (list of icon classes for displaying in the menu
*/
import {RoutePermissions, SharedPermissions} from './RouteLists';
import React from 'react';
import User from './User';
import {NavLink, Route} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import asyncComponent from 'util/asyncComponent';


let routeKeyCount = 0;

export function buildNavList(returned = false){
	//Create instance of userClass
	//Create the menu list and routes based on dept/troupe permissions
	let menu = []; //navigational List (React Components)
	let routeList = []; //List of Routes (React Components)
	//Process list of department Specific Permissions
	RoutePermissions.forEach((deptGroup)=>{
		const deptID = deptGroup.deptID;
		const routes = deptGroup.routes;
		if(User.checkDept(deptID)){ //User assigned for the department
			menu.push(createNavHeader(deptGroup.name)); //Add Menu Header
			routes.forEach(routeGroup => { //Go through group of routes
				const p = checkPermitted(deptID, routeGroup);
				if(p.deptPermitted && p.directorPermitted){ //Permitted for Dept and Director/Troupe
					const linkName = routeGroup.name;
					const submenu = routeGroup.children != null;
					const route = routeGroup.route;
					const icon = routeGroup.iconClasses;
					const external = routeGroup.external;
					//console.log(linkName,route, submenu);
					if(routeGroup.menuDisplay){ //Sub Menu
						if(submenu){
							menu.push(createSubMenu(deptID, linkName,icon,routeGroup.children));
						} else{ //Single Element
							menu.push(createNavElement(linkName, external, submenu, route, icon));
						}
					}
					//Has a route - Add to Routes List
					if(routeGroup.route != null && routeGroup.route !== ''){
						const exact = routeGroup.exactMatch == null || routeGroup.exactMatch === false;
						routeList.push(createRoute(exact,routeGroup.route, routeGroup.component));
					}
					//Run through any children of the menu and add to routes list
					if(submenu){
						routeGroup.children.forEach(set=>{
							const exact = routeGroup.exactMatch != null || routeGroup.exactMatch === false;
							if(User.checkUserLevel(deptID,set.managerLevel)) { //Has Permission
								routeList.push(createRoute(exact, set.route, set.component));
							}
						});
					} //End submenu check
				} //End Dept/Director Troupe Check
			}) //End routes forEach loop
		} //End user dept verification
	}); //End route permissions loop
	//Run through list of Shared Permissions
	SharedPermissions.forEach(page => {
		const allowedDepts = page.deptIDs;
		let permitted = false;
		for(const dept of allowedDepts) {
			if (User.checkUserLevel(dept[0], dept[1])) {
				permitted = true;
				break;
			}
		}
		if(permitted){
			//Display the menu
			if(page.menuDisplay){
				menu.push(createNavElement(page.name, page.external, false, page.route,page.iconClasses))
			}
			//Add Route
			routeList.push(createRoute(page.exactMatch,page.route,page.component));
		}
	});
	//return navlist and route list
	if(returned === 'routes'){
		return routeList;
	} else{
		return menu;
	}
}

let liKeyCount = 0;
const getLiKeyCount =()=>{
	const currentKey = liKeyCount;
	liKeyCount++;
	return currentKey;
};
const createNavHeader =(deptName)=>{
	const key = getLiKeyCount();
	return (
		<li className="nav-header" key={key}>
			{deptName}
		</li>
	)
};
const createSubMenu =(deptID, name, icon, crotchGoblins )=>{
	let navChildren = [];
	 crotchGoblins.forEach((set,key) =>{
	 	//Permitted per the dept
		const p = checkPermitted(deptID, set);
	 	if(p.deptPermitted && p.directorPermitted) {
		    navChildren.push(createNavElement(set.name, set.external, false, set.route, set.icon, 1));
	    }
	});
	const key = getLiKeyCount();
	return (
		<li className="menu collapse-box" key={key}>
			<Button>
				<i className={`zmdi zmdi-hc-fw ${icon}`}/>
				<span className="nav-text">{name}</span>
			</Button>
			<ul className="sub-menu">
				{navChildren}
			</ul>
		</li>
	)
};

const checkPermitted =(deptID, routeSet)=>{
	//Check permissions based on deptID and manager/director settings of the specific route
	//Manager Strict defaults to false unless specifically set.
	const managerStrict = routeSet.managerStrict != null ? routeSet.managerStrict : false;
	//Check dept/manager permissions for the route
	const deptPermitted = User.checkUserLevel(deptID, routeSet.managerLevel, managerStrict);
	//default director permitted to true by default (only affects acting dept with ID of 6)
	let directorPermitted = true;
	if(deptID === 6){
		//Additional permission check for directors
		//Set strict to false by default; otherwise set to strict setting
		const directorStrict = routeSet.directorStrict != null ? routeSet.directorStrict : false;
		//Set Director Level to 0 (Generic Actor Level) by default; otherwise set to specified level
		const directorLevel = routeSet.directorLevel != null ? routeSet.directorLevel : 0;
		directorPermitted = User.checkDirectorLevel(directorLevel,directorStrict);
	}
	return {deptPermitted, directorPermitted};
};

const createNavElement = (name, externalLink, subMenu, route, iconClasses, depth = 0)=>{
	//Build a menu element
	//console.log(name,route);
	let liClass = ''; //menu no-arrow
	let navLinkClass = '';
	let icon = depth === 0 ? <i className={`zmdi zmdi-hc-fw ${iconClasses}`} /> : <i className={`zmdi ${iconClasses}`} />;
	if(subMenu){
		navLinkClass = 'prepend-icon';
		liClass = 'menu collapse-box';
		icon = null;
	}
	const key = getLiKeyCount();
	if(externalLink){
		//This is a link out of the website to access a file or something
		//No routes through react
		return (
			<li className={liClass} key={key}>
				<a className={navLinkClass} href={route} target='_blank'>
					{icon}
					<span className="nav-text"><span>{name}</span></span>
				</a>
			</li>
		);
	} else {
		//This is an internal link
		//use Routes
		return (
			<li className={liClass} key={key}>
				<NavLink className={navLinkClass} to={route}>
					{icon}
					<span className="nav-text"><span>{name}</span></span>
				</NavLink>
			</li>
		);
	}
};
const createRoute = (exactMatch, route, reactComponent)=>{
	//Build a react router element
	const link = '';
	const fullRoute = `${link}${route}`;
	const keyIndex = routeKeyCount;
	routeKeyCount++;
	const component = asyncComponent(() =>reactComponent);
	if(exactMatch){
		return (
			<Route
				exact path={fullRoute}
				key={keyIndex}
				component={component} // import('ait/routes/TestRoute')
			/>
		);
	} else{
		return (
			<Route path={`${link}${route}`}
			       key={keyIndex}
			       component={component}
			/>
		);
	}
};
//Generic Icon for Show Menu items within the Navigational Menu
