import React from 'react';
import {Button, Grid, TextField} from '@material-ui/core';
import {BugReport, Close} from "@material-ui/icons";
import Modal from "ait/components/Dialogs/Modal";
import AITAlert from "ait/components/AITAlert";
import SecureConnect from "ait/system/SecureConnect";
import {getSpacing} from "ait/system/UtilityFunctions";
import {Form, Formik} from "formik";
import * as yup from 'yup';

class Footer extends React.Component {
	state = {
		displayModal: false,
		submitPending: false,
		submissionSuccessful: false,
		attemptedAction: '',
		actionGoal: '',
		problemExperienced: '',
		userExpectation: '',
	};
	handleChange(e){
		const {name, value} = e.target;
		this.setState({[name]: value});
	}
	toggleModal(e, visible){
		this.setState({displayModal: visible});
		if(!visible){
			this.resetForm();
		}
	}
	resetForm(){
		this.setState({
			submitPending: false,
			submissionSuccessful: false,
			attemptedAction: '',
			actionGoal: '',
			problemExperienced: '',
			userExpectation: '',
		});
	}

	reportBug() {
		let sc = new SecureConnect('system.php','post');
		const {attemptedAction, problemExperienced, actionGoal, userExpectation} = this.state;
		const formData = {
			attemptedAction,
			problemExperienced,
			actionGoal,
			userExpectation,
			interfaceAddress: window.location.href
		};
		this.setState({submitPending: true});
		sc.setAction('reportBug');
		sc.setFormData(formData);
		sc.connect().then(json=>{
			//Determine if the bug has been submitted based on the completion status
			this.setState({submissionSuccessful: sc.getCompleted(json), submitPending: false});
		});
	}

	render() {
		const date = new Date();
		const dateYear = date.getFullYear();
		const handleChange = this.handleChange.bind(this);
		const s = this.state;
		const userTextLength = 25;
		const userTextMaxLength = 2048;
		const validationSchema = yup.object().shape({
			attemptedAction: yup.string()
				.required('Required')
				.min(userTextLength, `Please elaborate further (minimum ${userTextLength} characters)`)
				.max(userTextMaxLength, `Text too long (maxium of ${userTextMaxLength} characters)`),
			problemExperienced: yup.string()
				.required('Required')
				.min(userTextLength, `Please elaborate further (minimum ${userTextLength} characters)`)
				.max(userTextMaxLength, `Text too long (maxium of ${userTextMaxLength} characters)`),
			actionGoal: yup.string()
				.required('Required')
				.min(userTextLength, `Please elaborate further (minimum ${userTextLength} characters)`)
				.max(userTextMaxLength, `Text too long (maxium of ${userTextMaxLength} characters)`),
			userExpectation: yup.string()
				.required('Required')
				.min(userTextLength, `Please elaborate further (minimum ${userTextLength} characters)`)
				.max(userTextMaxLength, `Text too long (maxium of ${userTextMaxLength} characters)`),
		});
		return (
			<footer className="app-footer">
			<span
				className="d-inline-block">Murder Mystery Company / American Immersion Theater &copy; {dateYear}</span>
			<Button
				size="medium"
				color="secondary"
				disabled={s.displayModal === true}
				onClick={()=>this.toggleModal.bind(this)(null, true)}
			><BugReport/> Report A Bug</Button>
			<Modal
				open={s.displayModal}
				title={'Report A Bug'}
				closeFunction={()=>this.toggleModal.bind(this)(null, false)}
			>
				<Formik
					initialValues={this.state}
					validationSchema={validationSchema}
					onSubmit={this.reportBug.bind(this)}
					enableReinitialize={true}
				>
				{({errors, touched}) => (
					<Form>
						<Grid container spacing={8}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label={'What action are you attempting?'}
									placeholder={"Briefly explain what you're attempting to do. Example: I'm trying to update my account information."}
									multiline={true}
									rows={4}
									error={errors.attemptedAction && touched.attemptedAction}
									helperText={errors.attemptedAction || ''}
									name={'attemptedAction'}
									value={s.attemptedAction}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label={'What problem are you experiencing?'}
									placeholder={"Briefly explain what error you're experiencing. Examples: 'the submission button is disabled', `a form field is wrongly displaying an error`, `I can't find information I expect to be here or information is missing`. Please be as specific as possible."}
									multiline={true}
									rows={4}
									error={errors.problemExperienced && touched.problemExperienced}
									helperText={errors.problemExperienced || ''}
									name={'problemExperienced'}
									value={s.problemExperienced}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label={'What is the goal of this action?'}
									placeholder={"Please explain the goal of the action you're performing. Example: I'm updating my account information to correct the spelling of my name."}
									multiline={true}
									rows={4}
									error={errors.actionGoal && touched.actionGoal}
									helperText={errors.actionGoal || ''}
									name={'actionGoal'}
									value={s.actionGoal}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label={'What is the expected outcome of this action? (How do you expect it to work?)'}
									placeholder={'Please explain what a successful outcome would look like. Example: My corrected name will be saved in the system.'}
									multiline={true}
									rows={4}
									error={errors.userExpectation && touched.userExpectation}
									helperText={errors.userExpectation || ''}
									name={'userExpectation'}
									value={s.userExpectation}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button
									fullWidth
									type={'submit'}
									variant={'contained'}
									color={'primary'}
									disabled={s.submitPending || s.submissionSuccessful}
								>Report Bug</Button>
							</Grid>
							<Grid item xs={12}>
								<AITAlert
									visible={s.submissionSuccessful}
									status={'success'}
									headline={'Bug Reported'}
									message={'Thank you for reporting your issue. Your submission will be reviewed shortly. You can now close this window.'}
								>
									<Button
										fullWidth
										style={{marginTop: getSpacing()}}
										color={'primary'}
										variant={'contained'}
										onClick={()=>this.toggleModal.bind(this)(null, false)}
									><Close/> Close Form</Button>
								</AITAlert>
							</Grid>
						</Grid>
					</Form>
				)}
				</Formik>

			</Modal>

			</footer>
		);
	}
}

export default Footer;
