import amberTheme from 'containers/themes/amberTheme';
import blueTheme from 'containers/themes/blueTheme';
import cyanTheme from 'containers/themes/cyanTheme';
import greenTheme from 'containers/themes/greenTheme';
import indigoTheme from "containers/themes/indigoTheme";
import orangeTheme from 'containers/themes/orangeTheme';
import pinkTheme from 'containers/themes/pinkTheme';
import purpleTheme from 'containers/themes/purpleTheme';
import darkTheme from "containers/themes/darkTheme";

const ColorThemes = {
	"indigo" : indigoTheme,
	"cyan": cyanTheme,
	"amber": amberTheme,
	"deep-orange": orangeTheme,
	"pink": pinkTheme,
	"blue": blueTheme,
	"deep-purple": purpleTheme,
	"green": greenTheme,
	"dark-indigo": indigoTheme,
	"dark-cyan": indigoTheme,
	"dark-amber": indigoTheme,
	"dark-deep-orange": indigoTheme,
	"dark-pink": indigoTheme,
	"dark-blue": indigoTheme,
	"dark-deep-purple": indigoTheme,
	"dark-green": indigoTheme,
	'dark-theme': darkTheme,
};

export const DefaultTheme = 'dark-indigo';

export default ColorThemes;