import SecureConnect from 'ait/system/SecureConnect';
import ClientStore from './ClientStore';
import ColorThemes from "./ColorThemes";
import {DefaultTheme} from "./ColorThemes";
import {PRIVATE_DEPT_ID, PRODUCTION_DEPT_ID, ACCOUNTING_DEPT_ID, ACTOR_DEPT_ID, LOGISTICS_DEPT_ID, DIRECTOR_LEVEL} from "./AITConstants";
import {
	updateActorCheck,
	updateUser,
	updateUserDetails,
	setPending,
	setRedirectURL,
	setDarkTheme,
	setThemeColor,
	TestIncrement,
} from 'actions/index';

export class User{
	constructor(){
		this.checkType = '';
		this.checkID = 0;
		this.strict = false;
		this.showConsole = false;
	}
	logout(connect = true){
		if(this.showConsole){
			console.log('***Logging out');
		}
		this.setClientStorePermissions(false,{});
		let sc = new SecureConnect('system.php?action=logout');
		sc.setDisplaySuccessMessages(false);
		sc.terminateSessionTimestamp();
		if(connect) {
			sc.connect();
		}
		this.setThemeColor(DefaultTheme);
		//Terminate storage
		Object.keys(localStorage).forEach(key=> {
			localStorage.removeItem(key);
		});
		Object.keys(sessionStorage).forEach(key =>{
			sessionStorage.removeItem(key);
		});
		this.setAuth(false);
	}
	setClientStorePermissions(authorized, json = {}){
		//Establish person's authorization & permissions for system access
		//Set the store to match server permissions
		this.setAuth(authorized);
		//Send to Redux/State
		ClientStore.dispatch(updateUser(authorized, json));
		if(json.userInfo != null && json.userInfo.themeColor != null){
			const themeColor = json.userInfo.themeColor;
			this.setThemeColor(themeColor);
		}
		if(authorized === false){
			//Disconnected - reset theme
			this.setThemeColor(DefaultTheme);
		}
	}
	setClientUserDetails(data){
		//When the user updates there details (name, phone, email, mailing address)
		//Update the interface state
		ClientStore.dispatch(updateUserDetails(data));
	}
	setThemeColor(colorCode){
		if(colorCode === false || colorCode == null || colorCode === ''){
			//Die early
			return;
		}
		const statusColors = ColorThemes[colorCode].palette.statuses || {};
		const body = document.body.classList;
		const themes = Object.keys(ColorThemes);
		if(colorCode === 'dark-theme'){
			ClientStore.dispatch(setDarkTheme(statusColors));
		} else{
			ClientStore.dispatch(setThemeColor(colorCode, statusColors));
		}
		//Remove the themes
		themes.forEach(theme=>{
			body.remove(theme);
		});
		//Add theme
		body.add(colorCode);
	}
	static setActorCheck(setting, value){
		ClientStore.dispatch(updateActorCheck(setting, value));
	}
	static getAuthString(){
		return 'systemAuthUser';
	}
	static TestIncrement(){
		ClientStore.dispatch(TestIncrement());
	}
	setAuth(loggedIn){
		if(loggedIn) {
			localStorage.setItem(User.getAuthString(), loggedIn);
		} else{
			if(this.showConsole) {
				console.log('***unsetting Auth');
			}
			localStorage.setItem(User.getAuthString(), loggedIn);
		}

		if(this.showConsole){
			console.log("------\r\n",'***AUTH = ',User.checkAuth());
		}
	}
	static checkAuth(){
		if(this.showConsole) {
			console.log('***getting auth');
		}
		const authSetting = User.getAuthString();
		return localStorage.getItem(authSetting) != null && localStorage.getItem(authSetting) === true;
	}
	static updateSessionPending(pendingValue){
		const store = ClientStore.getState();
		if(store.AITUser != null && store.AITUser.pending < pendingValue){
			ClientStore.dispatch(setPending(pendingValue));
		}
	}
	static setRedirectURL(url = false){
		const store = ClientStore.getState();
		if(store.AITUser != null){
			url = url !== false && url != null ? url : '';
			ClientStore.dispatch(setRedirectURL(url));
		}
	}
	static checkCoreSuperUser(){
		let coreTest = false;
		const store = ClientStore.getState();
		if(store.AITUser != null){
			//Compare user ID to the list of core super users
			const {account} = store.AITUser;
			coreTest = account.coreSuSet.includes(parseInt(account.userInfo.user_ID));
		}
		return coreTest;
	}
	static async checkSession(){
		let sc = new SecureConnect('system.php?action=authUser','get');
		sc.setDisplaySuccessMessages(false);
		sc.setDisplayNotifications(false);
		User.updateSessionPending(1);
		sc.connect().then(jsonResponse =>{
			//1b. Check completed (user logged in)
			if(sc.getCompleted(jsonResponse)){
				//1c. Assign troupe assignments, permissions, user settings to redux
				//1d. Return true;
				return true;
			} else{
				//No longer pending status for the session
				User.updateSessionPending(2); //was completed
				return false;
			}
		});
	}
	static async checkLoginStatus(){
		//Called on Refresh/Initial Page Load
		//1. Determine if user previously logged in
		if(this.showConsole) {
			console.log('***CheckinLoginStatus(): checking login status');
		}
		if(!User.checkAuth()) {
			//Failed - not logged in.
			return false;
		}
		if(this.showConsole) {
			console.log('***local auth true');
			console.log('***Connecting to system to load Permissions');
		}
		//1a. Pull credentials from the system
		const sc = new SecureConnect('system.php?action=authUser','get');
		sc.setDisplaySuccessMessages(false);
		sc.setDisplayNotifications(false);
		sc.connect().then(jsonResponse =>{
			//1b. Check completed (user logged in)
			if(sc.getCompleted(jsonResponse)){
				//1c. Assign troupe assignments, permissions, user settings to redux
				if(this.showConsole) {
					console.log(jsonResponse);
				}
				//1d. Return true;
				
				return true;
			} else{
				//Not valid - logout
				if(this.showConsole){
					console.log('*** INVALID USER');
					console.log(jsonResponse);
				}
			}
		});
	}
	getURL(){
		return 'localhost:3000';
	}
	static checkDept(dept){
		return this.checkUserLevel(dept,0);
	}
	static checkUserLevel(dept, level = 0, strict = false){
		//Alias of userLevel
		this.setTypes('dept',dept, level, strict);
		return this.checkLevel();
	}
	static getPermittedFormOptions(editedUserSettings, type, userID){
		//Return a list of permissions that are editable by the viewer of the user
		//Run through depts/troupeAssignments (based on "groupLabel")
		//Return instances (deptID's/TroupeID's) when the user's permissions are higher than the editedUserSettings
		//Editor's managerial permissions - Default = dept Permissions
		let permittedSet = []; //Settings (depts/troupes) Editor is allowed to modify
		let deptMinLevel = 1; //Starting Required Level = ASM+
		let dirMinLevel = DIRECTOR_LEVEL; //Starting Director Level = Standard Director
		let dirRoleMinLevel = 0;
		if(this.getUserID() === parseInt(userID)){
			//SElF USER - DISPLAY ALL DEPT SETTINGS - NOT JUST EDITABLE ONES
			deptMinLevel = 0; //Standard user
			dirMinLevel = 0; //Standard Actor
		}
		let editorPermissions = [];
		let setType = 'depts';
		let sectionKey = 'dept_ID';
		let sortKey = 'section';
		editorPermissions = User.getManagerDepts(deptMinLevel);
		//Exception for directors - they need to have actor dept available
		if(this.isDirector() && editorPermissions.filter(item =>(parseInt(item.dept_ID) === 6)).length < 1){
			let basicUserPermissions = User.getManagerDepts(0);
			let actorDeptPermission = basicUserPermissions.filter(item =>(parseInt(item.dept_ID) === 6))[0];
			//Set as Assistant manager - get the User selector
			actorDeptPermission.managerLevel = 1;
			editorPermissions.push(actorDeptPermission);
		}

		if(type.toLowerCase().includes('troupe')){
			editorPermissions = User.getDirectorTroupes(dirMinLevel);
			setType = 'troupe';
			sectionKey = 'troupe_ID';
			sortKey = setType;
		}
		if(type.toLowerCase().includes('name')){
			editorPermissions = User.getDirectorRoleList(0);
			setType = 'name';
			sectionKey = 'roleName_ID';
			sortKey = setType;
		}
		let permKeys = User.getPermissionKeys(setType);
		//Create list of the user's settings
		let keys = []; //Object.keys(editedUserSettings);
		if(editedUserSettings != null){
			keys = Object.keys(editedUserSettings);
		}
		editorPermissions.forEach(set=> {
			//Run through the list of editor's available permissions to assign
			//Get the key label for dept_ID or troupe_ID
			const keyID = permKeys.groupKey;
			//Assign deptID or TroupeID to "groupID"
			const groupID = parseInt(set[keyID]);
			//convert to int
			set[sectionKey] = parseInt(set[sectionKey]);
			set['managerLevel'] = parseInt(set['managerLevel']);
			//Find instances of the viewer's dept/troupe within the user's permissions settings
			let ag = null;
			const activeGroup = keys.filter(k => {
				if(parseInt(editedUserSettings[k][keyID]) === groupID){
					ag = editedUserSettings[k];
					return true;
				} else{
					return false;
				}
			});
			//Found an instance - This dept/troupe is active
			const active = activeGroup.length > 0 ? 1: 0;
			//Set the value of the current user's permissions
			const settingValue = active === 1 ? parseInt(ag.managerLevel) : 0;
			let final = {sectionID: groupID, data: {...set, setType, active, settingValue}};
			final = {...set, setType, active, settingValue};
			permittedSet.push(final);
		});

		permittedSet.sort((a,b)=>(a[sortKey] > b[sortKey] ? 1:-1));
		return permittedSet;
	}
	static getPermissionKeys(type){
		let keys={groupKey: 'dept_ID', levelKey: 'managerLevel'};
		if(type.toLowerCase().includes('troupe')){
			keys={groupKey: 'troupe_ID', levelKey: 'managerLevel'};
		}
		if(type.toLowerCase().includes('name')){
			keys={groupKey: 'roleName_ID', levelKey: 'managerLevel'};
		}
		return keys;
	}
	static getUserID(){
		//Return the userID for the user
		//Use this to run checks to make sure the person can't edit their own permissions
		const userData = this.getPermissions();
		return parseInt(userData.userInfo.user_ID);
	}
	static getManagerDepts(level = 1, strict = false){
		//Return a list of depts the user is a manager of
		//used when creating a new user
		return this.getManagerDirectorListings('permissions','managerLevel',level, strict);
	}
	static getDirectorTroupes(level = DIRECTOR_LEVEL, strict){
		//Return a list of troupes in which the user is a director
		//Used when creating a new actor or assigning troupes to existing actor
		let troupes = this.getManagerDirectorListings('troupeAssignments','managerLevel',level, strict);
		return troupes.sort((a,b)=>(a['troupe'] > b['troupe'] ? 1:-1));
	}
	static getAssistantDirectorTroupes(level = 1, strict){
		let troupes = this.getManagerDirectorListings('troupeAssignments','managerLevel',level, strict);
		return troupes.sort((a,b)=>(a['troupe'] > b['troupe'] ? 1:-1));
	}
	static getTroupeList(){
		return this.getDirectorTroupes(0,false);
	}
	static getDirectorRoleList(level = DIRECTOR_LEVEL, strict){
		let roles = this.getManagerRoleListings('approvedRoles','managerLevel',level,strict);
		return roles.sort((a,b)=>(a['name'] > b['name'] ? 1:-1));
	}
	static getRoleList(){
		return this.getDirectorRoleList(0,false);
	}
	static getManagerDirectorListings(permissionsGroup, levelKey, level = DIRECTOR_LEVEL, strict = false){
		//Return a list of dept/troupes where the user is a manager/director
		//permissionsGroup = 'permissions' / 'troupeAssignments';
		const requiredLevel = parseInt(level);
		let managerSettings = [];
		const userData = this.getPermissions();
		if(!userData.hasOwnProperty(permissionsGroup)){
			return managerSettings; //Return empty array - not a director/manager
		}
		const specificPermissions = userData[permissionsGroup];
		const keys = Object.keys(specificPermissions);
		keys.forEach(k =>{
			const permissionSet = specificPermissions[k];
			const setLevel = parseInt(permissionSet[levelKey]);
			if((strict && requiredLevel === setLevel) || (!strict && requiredLevel <= setLevel)){
				managerSettings.push(permissionSet);
			}
		});
		return managerSettings;
	}
	static getManagerRoleListings(permissionsGroup, levelKey, level = DIRECTOR_LEVEL, strict = false){
		//Return a list of dept/troupes where the user is a manager/director
		//permissionsGroup = 'permissions' / 'troupeAssignments';
		const requiredLevel = parseInt(level);
		let managerSettings = [];
		const userData = this.getPermissions();
		if(!userData.hasOwnProperty(permissionsGroup)){
			return managerSettings; //Return empty array - not a director/manager
		}
		const specificPermissions = userData[permissionsGroup];
		const keys = Object.keys(specificPermissions);
		keys.forEach(k =>{
			const permissionSet = specificPermissions[k];
			const setLevel = parseInt(permissionSet[levelKey]);
			if((strict && requiredLevel === setLevel) || (!strict && requiredLevel <= setLevel)){
				managerSettings.push(permissionSet);
			}
		});
		return managerSettings;
	}
	static checkDirectorLevel(level, strict = false){
		level = parseInt(level);
		const permissions = this.getPermissions();
		if(!permissions.hasOwnProperty('troupeAssignments')){
			//No Troupe Assignments - definitely not a director
			return false;
		}

		//Check if the user is a member of the production staff
		const productionCheck = User.checkUserLevel(PRODUCTION_DEPT_ID,0,false);
		if(productionCheck){
			//User is a member of the production staff. Automatically grant access
			return true;
		}

		const set = permissions['troupeAssignments'];
		for(let k of Object.keys(set)){
			const troupe = set[k];
			//IF Not strict and required level less than assigned level -OR- Strict && levels match
			if((!strict && level <= parseInt(troupe.managerLevel)) || (strict && level === parseInt(troupe.managerLevel))){
				//Access permitted. End loop.
				return true;
			}
		}
		// const roles = permissions['approvedRoles'];
		// for(let r of Object.keys(roles)){
		// 	// const role = set[k];
		// 	return true;
		// }
		//Not Permitted
		return false;
	}
	static checkTroupeLevel(troupeID, level, strict = false){
		this.setTypes('troupe',troupeID,level,strict);
		return this.checkLevel();
	}
	static checkRoleLevel(roleNameID, level, strict = false){
		this.setTypes('name',roleNameID,level,strict);
		return this.checkLevel();
	}
	static setTypes(checkType,checkID,requiredLevel,strict){
		this.checkType = checkType;
		this.checkID = parseInt(checkID);
		this.requiredLevel = parseInt(requiredLevel);
		this.strict = !!parseInt(strict);
	}
	static isDirector(level = DIRECTOR_LEVEL, strict = false){
		//Must be a member of the acting dept
		const isActor = User.isActor();
		//Must have a troupe which they're a director of
		const hasDirectorTroupes = User.checkDirectorLevel(level, strict);
		return hasDirectorTroupes && isActor;
	}
	static isAssistantDirector(level = 1, strict = false){
		//Must be a member of the acting dept
		const isActor = User.isActor();
		//Must have a troupe which they're a director of
		const hasAssistantDirectorTroupes = User.checkDirectorLevel(level, strict);
		return hasAssistantDirectorTroupes && isActor;
	}
	static isAccounting(){
		return User.checkDept(ACCOUNTING_DEPT_ID);
	}
	static isProduction(){
		return User.checkDept(PRODUCTION_DEPT_ID);
	}
	static isPrivateSales(){
		return User.checkDept(PRIVATE_DEPT_ID);
	}
	static isActor(){
		return User.checkDept(ACTOR_DEPT_ID);
	}
	static isLogistics(){
		return User.checkDept(LOGISTICS_DEPT_ID);
	}
	static checkLevel(){
		//Check the permissions from redux store
		const systemPermissions = this.getPermissions();
		const type = this.checkType === 'dept' ? 'permissions': 'troupeAssignments';
		const levelKey = 'managerLevel';
		const idSet = systemPermissions[type] != null && systemPermissions[type][this.checkID] != null;
		if(idSet){
			//User is assigned to dept or troupe. Now determine if the user has the permissions for the section
			const setUserLevel = parseInt(systemPermissions[type][this.checkID][levelKey]);
			if((this.strict && this.requiredLevel === setUserLevel) || (!this.strict && this.requiredLevel <= setUserLevel)){
				return true;
			}
		}
		return false;
	}
	static getForcedPasswordReset() {
		const userData = User.getUserData();
		//return false if no userdata, otherwise return setting
		return userData.account.userInfo == null ? false : parseInt(userData.account.userInfo.resetPass) === 1;
	}
	static getUserData(){
		const store = ClientStore.getState();
		return store.AITUser;
	}
	static getSessionToken() {
		const {account} = this.getUserData();
		return account.userInfo != null ? account.userInfo.token : '';
	}
	static getPermissions(){
		const store = ClientStore.getState();
		return store.AITUser.account;
	}
	static getSettings(){
		const store = ClientStore.getState();
		return store.settings;
	}
	static getStatusColors(){
		const store = ClientStore.getState();
		return store.settings.statusColors;
	}
}

export default User;
