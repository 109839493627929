import cyan from '@material-ui/core/colors/cyan';
import deepOrange from "@material-ui/core/colors/deepOrange";

export default {
	palette: {
		primary: {
			light: cyan[300],
			main: cyan[500],
			dark: cyan[700],
			contrastText: '#fff'
		},
		secondary: {
			light: deepOrange[300],
			main: deepOrange['A200'],
			dark: deepOrange[700],
			contrastText: '#fff'
		},
		statuses: {
			success: '#3bcc00',
			info: '#00aacc',
			warning: '#e59900',
			error: '#e53a36',
			noAction: '#d9d9d9',
		},
	},
	status: {
		danger: 'orange',
	},

		typography: {
			button: {
			fontWeight: 400,
			textAlign: 'capitalize'
		},
	},
};
