import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import {NotificationContainer} from 'react-notifications';
import {
	ABOVE_THE_HEADER,
	BELOW_THE_HEADER,
	COLLAPSED_DRAWER,
	FIXED_DRAWER,
	HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import UserIdleSessionDialog from "ait/components/System/UserIdleSessionDialog";
import SecureConnect from "ait/system/SecureConnect";


import {buildNavList} from 'ait/system/RoutePermissions';

class AITApp extends React.Component {
	state = {
		lastLogin:'1970-01-01 00:00:00',
		denyAccess: false,
		dataLoaded: false
	}
	componentDidMount(){
		this.getUser();
	}
	getUser(){
		let queryString = null;
		queryString = '?action=getMyProfile';
		
		let sc = new SecureConnect(`user.php${queryString}`,'get');
		sc.setDisplayNotifications(false);
		sc.connect().then(json => {
			if(sc.getCompleted(json)) {
				const response = sc.getResponse(json);
				
				if(response.code === 4){ //ERROR - DENIED OR DOESNT EXIST
						this.setState({denyAccess: true});
					} else {
						// let state = this.state;
						const userData = sc.getData(json);
						// state.lastLogin = sc.getData(json).lastLogin;
						this.setState({lastLogin: userData.lastLogin},() => this.lastLoginLoaded(true));
					}
				} 
			}
		)
	}
	lastLoginLoaded(loaded = false) {
		// this.state.dataLoaded = loaded;
		this.setState({dataLoaded:loaded});
	}
	render() {
		//const AITUser = this.props.AITUser;
		const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
		let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';
		if (this.state.dataLoaded) {
			if((new Date(this.state.lastLogin)) === 'Invalid Date') drawerStyle = 'fixed-drawer';
		}

		//set default height and overflow for iOS mobile Safari 10+ support.
		if (isIOS && isMobile) {
			document.body.classList.add('ios-mobile-view-height')
		}
		else if (document.body.classList.contains('ios-mobile-view-height')) {
			document.body.classList.remove('ios-mobile-view-height')
		}
		const navRoutes = buildNavList('routes');
		return (
			<div className={`app-container ${drawerStyle}`}>
				{/*<Tour/>*/}
				<Sidebar/> {/*NAVIGATION HERE*/}
				<div className="app-main-container">
					<div
						className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
						{(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
						<TopNav styleName="app-top-header"/>}
						<Header/>
						{(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
						<TopNav/>}
					</div>

					<main className="app-main-content-wrapper">
						<div className="app-main-content">
							<NotificationContainer/> {/*SERVER CONNECTION NOTIFICATIONS */}
							<UserIdleSessionDialog/>
							<Switch>
								{/*navRoutes are Permissions Specific */}
								{navRoutes}
								<Route exact path={`${match.url}`}
								       component={asyncComponent(() => import('./routes/latestTest/index'))}/>
								<Route component={asyncComponent(() => import('components/Error404'))}/>
							</Switch>
						</div>
						<Footer/>
					</main>
				</div>
			</div>
						);
	}
}


const mapStateToProps = (state) => {
	const {AITUser} = state;
	const {drawerType, navigationStyle, horizontalNavPosition} = state.settings;
	return {AITUser: AITUser, drawerType, navigationStyle, horizontalNavPosition}
};
export default withRouter(connect(mapStateToProps)(AITApp));
